$min_width: 320px;
$max_width: 1366px;
$xl_regular_font: 18px;
$max_regular_font: 16px;
$min_regular_font: 13px;
$max_title_font: 32px;
$min_title_font: 22px;
$max_hero_title: 48px;
$min_hero_title: 28px;
$contact_min_text: 20px;
$min_footer_header: 20px;
$max_footer_header: 24px;

$chivo: "Lato", sans-serif;
$work-sans: "Roboto", sans-serif !important;
