/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
html,
body {
  font-size: 13px !important;
}
@media screen and (min-width: 320px) {
  html,
  body {
    font-size: calc(
          13px + 3 *
            ((100vw - 320px) / 1046)
        ) !important;
  }
}
@media screen and (min-width: 1366px) {
  html,
  body {
    font-size: 16px !important;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #f8f8ff;
  color: #454242;
  font-family: "Roboto", sans-serif !important;
}

:is(div, span, nav, a, ul, li, button):focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.main_content_position {
  margin-top: 75px;
}

.page__header {
  position: relative;
  padding: 6rem 2rem;
  background-color: #4654a3;
  margin-bottom: 5rem;
}
.page__header_title {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.page__header_title {
  font-size: 28px !important;
}
@media screen and (min-width: 320px) {
  .page__header_title {
    font-size: calc(
          28px + 20 *
            ((100vw - 320px) / 1046)
        ) !important;
  }
}
@media screen and (min-width: 1366px) {
  .page__header_title {
    font-size: 48px !important;
  }
}
.page__header_link {
  position: absolute;
  bottom: 16px;
  display: flex;
  align-items: center;
  color: #fff;
}
.page__header_link_text:first-child {
  text-decoration: underline;
}
.page__header_link_text:first-child:hover {
  cursor: pointer;
  text-decoration: none;
}