/* You can add global styles to this file, and also import other style files */
@use './app/scss/' as *;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  @include fluid-font(
    $min_width,
    $max_width,
    $min_regular_font,
    $max_regular_font
  );
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #f8f8ff;
  color: #454242;
  font-family: $work-sans;
}

:is(div, span, nav, a, ul, li, button):focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.main_content_position {
  margin-top: 75px;
}

.page__header {
  position: relative;
  padding: 6rem 2rem;
  background-color: color(secondary);
  margin-bottom: 5rem;

  &_title {
    font-family: $chivo;
    color: color(light);
    font-weight: bold;
    text-align: center;
    @include fluid-font(
      $min_width,
      $max_width,
      $min_hero_title,
      $max_hero_title
    );
  }

  &_link {
    position: absolute;
    bottom: 16px;
    display: flex;
    align-items: center;
    color: color(light);

    &_text:first-child {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
